<template>
  <div class="min-h-screen my-10 sela-container">
    <div class="mb-10 text-xl uppercase">{{ t('privacy') }}</div>
    <div v-if="privacy" style="line-height: 2rem" v-html="privacy"></div>
  </div>
</template>

<script setup lang="ts">
const privacy = await useGroupSettings(10, 'privacy')

const { url } = useDomainHost()
const { t } = useI18n()

useServerSeoMeta(
  useOgMeta(
    url + '/privacy',
    t('privacy'),
    t('sila_description'),
    url + '/images/sela.webp'
  )
)

useHead({
  title: t('privacy'),
  link: [useSeoCanonical(url + '/privacy')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('privacy'), url + '/privacy')
    ])
  ]
})
</script>
